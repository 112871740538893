const BACK_END = [
  {
    title: 'Project setup',
    content: [
      'NodeJS, MySQL, Visual Code, Git, Postman',
      'MacOS / Linux, Windows'
    ]
  },
  {
    title: 'NodeJS and ExpressJS introduction',
    content: [
      'NPM introduction',
      'ExpressJS: Framework structure',
      'ExpressJS: Introduce to request/response cycle',
      'Set up the error handling, logging and database connection',
      'ORM: Sequelize for MySQL'
    ]
  },
  {
    title: 'System architecture',
    content: [
      'How do we come up with a technical design for a particular feature?',
      'Database design, API design and modules organization'
    ]
  },
  {
    title: 'Feature 1: Sign up and Sign in [Authorization focus]',
    content: [
      'Design the database schema and API',
      'Authorization with JWT',
      'Organize authorization flow with middleware',
      'Authentication vs Authorization'
    ]
  },
  {
    title: 'Feature 2: Products and Categories [CRUD focus]',
    content: [
      'Design the database relationships between tables',
      'CRUD operations for Products and Categories',
      'Images upload: files, database or on the cloud?'
    ]
  },
  {
    title: 'Feature 3: Querying, filtering, sorting, and paginating products [SQL focus]',
    content: [
      'Write complex and reusable SQL to retrieve correct products',
      'Pagination to rescue the performance'
    ]
  },
  {
    title: 'Feature 4: Checkout and Order [Payment focus]',
    content: [
      'Stripe integration',
      'Webhooks to receive payment status'
    ]
  }
]

const FRONT_END = [
  {
    title: 'Project setup: React, TailwindCSS',
    content: []
  },
  {
    title: 'Basic Concepts of React',
    content: [
      'What is React?',
      'State & Props',
      'Component Lifecycle',
      'Handling Events',
      'Hooks'
    ]
  },
  {
    title: 'System architecture',
    content: [
      'Modules, layers and components organization'
    ]
  },
  {
    title: 'Feature 1: Sign-up and Sign-in form',
    content: [
      'Build your first form (and validate it)',
      'Connect to the API',
      'Fix the CORS error'
    ]
  },
  {
    title: 'App navigation',
    content: [
      'React routing introduction',
      'Define our app navigation'
    ]
  },
  {
    title: 'App global state',
    content: [
      'Redux introduction: Why do we need to use it?',
      'Implement Redux store for authenticated token'
    ]
  },
  {
    title: 'Feature 2: Product listing',
    content: [
      'Implement the product cart component',
      'Implement the product listing page',
      'Implement the product detail page'
    ]
  },
  {
    title: 'Feature 3: Filter & Search',
    content: [
      'Implement various filters on the product listing page',
      'Global search: components communication problem'
    ]
  },
  {
    title: 'Feature 4: Card & Checkout',
    content: [
      'Implement the cart popup',
      'Use Redux to store the cart state',
      'Implement the checkout page',
      'Persist the cart information in the local storage'
    ]
  }
]

const DEPLOYMENT = [
  {
    title: 'Back-end',
    content: [
      'Deploy the server and the database',
      'Secure the ENV'
    ]
  },
  {
    title: 'Font-end',
    content: [
      'Production build and deploy'
    ]
  },
  {
    title: 'Setup your custom domain with CloudFlare'
  }
]

const COURSE_CONTENT = [
  {
    id: 'backend',
    moduleTitle: 'I. Back-end: NodeJS, ExpressJS, MySQL',
    moduleContent: BACK_END
  },
  {
    id: 'frontend',
    moduleTitle: 'II. Front-end: React',
    moduleContent: FRONT_END
  },
  {
    id: 'deployment',
    moduleTitle: 'III. Deployment',
    moduleContent: DEPLOYMENT
  }
]

export default COURSE_CONTENT
