<template>
  <div>
    <a
      v-if="toPricing"
      v-scroll-to="'#pricing'"
      href="#"
      target="_blank"
      class="btn btn-primary"
      :class="`font-${size}`"
    >Registration Closed</a>
    <a
      v-else
      href="#"
      target="_blank"
      class="btn btn-primary"
      :class="`font-${size}`"
    >Registration Closed</a>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: 'normal'
    },
    showDiscount: {
      type: Boolean,
      default: true
    },
    toPricing: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.font-small {
  font-size: 12px;
}
</style>
