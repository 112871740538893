<script>
/**
 * Navbar component
 */

import Cta from './cta.vue'

export default {
  components: {
    Cta
  },
  props: {
    isWhiteNavbar: {
      type: Boolean
    },
    navLight: {
      type: Boolean
    }
  },
  data () {
    return {
      isCondensed: false
    }
  },

  mounted: () => {
    window.onscroll = function () {
      onwindowScroll()
    }

    function onwindowScroll () {
      if (
        document.body.scrollTop > 50 ||
                document.documentElement.scrollTop > 50
      ) {
        document.getElementById('topnav').classList.add('nav-sticky')
      } else {
        document.getElementById('topnav').classList.remove('nav-sticky')
      }

      if (document.body.scrollTop > 100 ||
                document.documentElement.scrollTop > 100) {
        document.getElementById('back-to-top').style.display = 'inline'
      } else {
        document.getElementById('back-to-top').style.display = 'none'
      }
    }

    var links = document.getElementsByClassName('side-nav-link-ref')
    var matchingMenuItem = null
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i]
        break
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add('active')
      var parent = matchingMenuItem.parentElement

      /**
             * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
             * We should come up with non hard coded approach
             */
      if (parent) {
        parent.classList.add('active')
        const parent2 = parent.parentElement
        if (parent2) {
          parent2.classList.add('active')
          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.add('active')
            const parent4 = parent3.parentElement
            if (parent4) {
              const parent5 = parent4.parentElement
              parent5.classList.add('active')
            }
          }
        }
      }
    }
  },
  methods: {
    /**
         * Toggle menu
         */
    toggleMenu () {
      this.isCondensed = !this.isCondensed
      if (this.isCondensed) {
        document.getElementById('navigation').style.display = 'block'
      } else { document.getElementById('navigation').style.display = 'none' }
    },

    /**
         * Menu clicked show the submenu
         */
    onMenuClick (event) {
      event.preventDefault()
      const nextEl = event.target.nextSibling.nextSibling
      console.log(event.target.nextSibling.nextSibling)
      console.log(event.target.lastChild)
      console.log(event.target.childNodes)

      if (nextEl && !nextEl.classList.contains('open')) {
        const parentEl = event.target.parentNode
        if (parentEl) {
          parentEl.classList.remove('open')
        }
        nextEl.classList.add('open')
      } else if (nextEl) {
        nextEl.classList.remove('open')
      }
      return false
    }
  }
}
</script>

<template>
  <div>
    <!-- Navbar STart -->
    <header
      id="topnav"
      class="defaultscroll sticky"
      :class="{'bg-white': isWhiteNavbar === true}"
    >
      <div class="container">
        <div>
          <router-link
            v-if="navLight !== true"
            class="logo"
            to="/"
          >
            <img
              src="images/bezkoder-course-logo-dark.svg"
              height="44"
              alt=""
            >
          </router-link>
          <router-link
            v-else
            class="logo"
            to="/"
          >
            <img
              src="images/bezkoder-course-logo-light.svg"
              class="l-light"
              height="44"
              alt=""
            >
          </router-link>
        </div>
        <div class="buy-button">
          <cta size="small" />
          <!-- <a
            href="#"
            class="btn"
            :class="{'btn-light': navLight === true, 'btn-primary': navLight !== true}"
          >Register</a> -->
        </div>
        <div class="menu-extras">
          <div class="menu-item">
            <a
              class="navbar-toggle"
              :class="{'open': isCondensed === true}"
              @click="toggleMenu()"
            >
              <div class="lines">
                <span />
                <span />
                <span />
              </div>
            </a>
          </div>
        </div>

        <div id="navigation">
          <ul
            class="navigation-menu"
            :class="{'nav-light': navLight === true}"
          >
            <li>
              <router-link
                to="/"
                class="side-nav-link-ref"
              >
                Home
              </router-link>
            </li>
            <li class="has-submenu">
              <a
                href="#course-content"
              >Course Content
              </a>
            </li>
          </ul>
          <div class="buy-menu-btn d-none">
            <a
              href="#"
              class="btn btn-primary"
            >Buy Now</a>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>
