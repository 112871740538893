<script>
export default {
  data () {
    return {

    }
  }
}
</script>

<template>
  <div>
    <footer class="footer footer-bar">
      <div class="container text-center">
        <div class="row align-items-center">
          <div class="col-12">
            <div class="text-center">
              <p class="mb-0">
                © 2021 BezKoder Course. Developed with <i class="mdi mdi-heart text-danger" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
