<template>
  <div>
    <Navbar :nav-light="false" />

    <section
      id="home"
      class="bg-marketing d-table w-100"
      style="background: url('images/marketing/marketing-shape.png')"
    >
      <div class="container">
        <div class="row justify-content-center mt-5">
          <div class="col-lg-10 col-md-7 text-center">
            <div class="title-heading mt-0 mt-md-5 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <h1 class="h1 mb-3 text-uppercase">
                The Applied NodeJS and React Course
              </h1>
              <p class="para-desc text-muted mx-auto d-block">
                Learn to build a
                <span class="text-primary"> realworld e-commerce application </span>
                with
                <br>
                NodeJS, ExpressJS, React and MySQL
              </p>
              <div class="mb-5">
                <cta class="mt-4" />
              </div>
              <div>
                <img
                  class="w-100 shadow-lg rounded"
                  src="/images/store-front.jpg"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Feature Start -->
    <section class="section bg-light">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">
                Design and implement everything from scratch
              </h4>
              <p class="text-muted para-desc mx-auto mb-0">
                We show you how to decompose a business requirement into technical designs and
                implement it from back-end to front-end
                <br>
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div class="col-lg-4 col-md-6 mt-4 pt-2">
            <div class="media key-feature align-items-center p-3 rounded shadow">
              <div class="icon text-center rounded-circle mr-3">
                <activity-icon class="fea icon-ex-md text-primary" />
              </div>
              <div class="media-body">
                <h4 class="title mb-0">
                  Requirement Analysis
                </h4>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 mt-4 pt-2">
            <div class="media key-feature align-items-center p-3 rounded shadow">
              <div class="icon text-center rounded-circle mr-3">
                <database-icon class="fea icon-ex-md text-primary" />
              </div>
              <div class="media-body">
                <h4 class="title mb-0">
                  Database Design
                </h4>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 mt-4 pt-2">
            <div class="media key-feature align-items-center p-3 rounded shadow">
              <div class="icon text-center rounded-circle mr-3">
                <server-icon class="fea icon-ex-md text-primary" />
              </div>
              <div class="media-body">
                <h4 class="title mb-0">
                  Backend: Node, Express &amp; MySQL
                </h4>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 mt-4 pt-2">
            <div class="media key-feature align-items-center p-3 rounded shadow">
              <div class="icon text-center rounded-circle mr-3">
                <eye-icon class="fea icon-ex-md text-primary" />
              </div>
              <div class="media-body">
                <h4 class="title mb-0">
                  Front-end: React, Redux &amp; Tailwind
                </h4>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 mt-4 pt-2">
            <div class="media key-feature align-items-center p-3 rounded shadow">
              <div class="icon text-center rounded-circle mr-3">
                <lock-icon class="fea icon-ex-md text-primary" />
              </div>
              <div class="media-body">
                <h4 class="title mb-0">
                  JWT Authentication
                </h4>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 mt-4 pt-2">
            <div class="media key-feature align-items-center p-3 rounded shadow">
              <div class="icon text-center rounded-circle mr-3">
                <truck-icon class="fea icon-ex-md text-primary" />
              </div>
              <div class="media-body">
                <h4 class="title mb-0">
                  Deployment
                </h4>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-12 mt-4 pt-4 text-center">
            <a
              v-scroll-to="'#course-content'"
              class="btn btn-primary"
            >See Full Course Content... <i class="mdi mdi-arrow-down   " /></a>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Feature End -->

    <section class="section">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12 text-center">
            <div class="section-title ">
              <h4 class="title">
                Who is this course for
              </h4>
              <h4 class="text-primary para-desc mx-auto mb-4">
                Fresher and junior software engineers
              </h4>

              <p class="text-muted para-desc mx-auto">
                Ideally, you already have some knowledge about technology such as web-server, database,
                and you want to know how to craft these ones into a usable product.
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <div class="row align-items-center mt-100">
          <div class="col-12 text-center">
            <div class="section-title ">
              <h4 class="title mb-4">
                Why you should learn with us
              </h4>
              <p class="text-muted para-desc mx-auto mb-4">
                We have experience in building &amp; operating products that generate
                from <span class="text-primary"> a few thousand dollars to millions per month </span>, both B2C and B2B
              </p>

              <p class="text-muted para-desc mx-auto">
                We run the <a
                  href="https://bezkoder.com/"
                  target="_blank"
                  class="text-primary"
                >
                  BezKoder site</a> with hundred thousands visits from developers every month,
                so we know how to craft the course to fit with our audience
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
      </div>
    </section>

    <testimonial />

    <section
      id="course-content"
      class="section"
    >
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12 text-center">
            <div class="section-title ">
              <h4 class="title mb-4">
                Course Content
              </h4>
            </div>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-12">
            <div class="">
              <div
                id="course-content"
                class="accordion"
              >
                <b-card
                  v-for="(section, i) in courseContent"
                  :key="i"
                  no-body
                  class="mb-2 border-0 rounded"
                >
                  <b-card-header class="border-0 bg-light p-0">
                    <a
                      v-b-toggle="section.id"
                      class="position-relative text-dark d-block p-3 cursor-pointer"
                      href="javascript: void(0)"
                    >
                      <h6 class="title mb-0">
                        {{ section.moduleTitle }}
                      </h6>
                    </a>
                  </b-card-header>
                  <b-collapse
                    :id="section.id"
                    :visible="section.id === 'backend'"
                    accordion="course-content"
                  >
                    <b-card-body class="px-2 py-4">
                      <div
                        v-for="(sub, j) in section.moduleContent"
                        :key="j"
                      >
                        <h6>{{ sub.title }}</h6>
                        <ul class="text-muted">
                          <li
                            v-for="(c, k) in sub.content"
                            :key="k"
                          >
                            {{ c }}
                          </li>
                        </ul>
                      </div>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <intro-vid />

    <section class="section bg-dark-2 text-light mt-100">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12 text-center">
            <div class="section-title">
              <h4 class="h1 mb-4">
                Here are what you get
              </h4>
              <ul class="list-unstyled">
                <li class="">
                  <check-icon class="fea icon-sm text-light mr-1" />
                  Life-time access to all videos
                </li>
                <!-- <li class="">
                  <check-icon class="fea icon-sm text-light mr-1" />
                  40+ video lessons
                </li> -->
                <li class="">
                  <check-icon class="fea icon-sm text-light mr-1" />
                  Full source code of the application
                </li>
                <li class="">
                  <check-icon class="fea icon-sm text-light mr-1" />
                  Support from the instructors
                </li>
                <li class="">
                  <check-icon class="fea icon-sm text-light mr-1" />
                  Join our private alumni community
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      id="pricing"
      class="section pricing"
    >
      <div class="container main-cta">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title">
              <h4 class="h1 mb-2">
                Life-time price
              </h4>
              <p class="h5 text-muted mb-4">
                All content and all updates to the course in the future
              </p>
              <div>
                <span class="price">
                  $15
                </span>
                <span class="discount">
                  $70
                </span>
                <span class="badge badge-pill badge-danger ml-2">-80%</span>
              </div>
              <p class="mx-auto deals-desc">
                🙌  For early registers, the first <span class="text-primary"> 5 lessons are free</span>, plus
                the support from us if you encounter any issues
                <br>
                <br>
                After that, you can decide to purchase the course or not.
                If you still feel the course is not for you after your purchase, we will refund
                you 100% 🤝
              </p>
              <div class="watch-video mt-4 pt-2">
                <cta
                  class="mb-2"
                  :to-pricing="false"
                  :show-discount="false"
                />
              </div>
              <p class="mx-auto deals-desc">
                <strong>The course runs out of seats!<br>Thanks for your interest.</strong>
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
    </section>
    <Footer />
    <a
      id="back-to-top"
      v-scroll-to="'#topnav'"
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
    >
      <arrow-up-icon class="icons" />
    </a>
  </div>
</template>

<script>

import {
  ArrowUpIcon,
  EyeIcon,
  ServerIcon,
  LockIcon,
  TruckIcon,
  CheckIcon,
  ActivityIcon,
  DatabaseIcon
} from 'vue-feather-icons'

import Navbar from '@/components/navbar'
import Footer from '@/components/footer'
import Testimonial from '@/components/testimonial'
import IntroVid from '@/components/IntroVid.vue'

import COURSE_CONTENT from './course_content'
import Cta from '../components/cta.vue'

export default {
  components: {
    Navbar,
    Footer,
    ArrowUpIcon,
    EyeIcon,
    ServerIcon,
    LockIcon,
    TruckIcon,
    CheckIcon,
    ActivityIcon,
    DatabaseIcon,
    Cta,
    Testimonial,
    IntroVid
  },
  data () {
    return {
      courseContent: COURSE_CONTENT
    }
  }
}
</script>

<style scoped lang="scss">
@import 'public/scss/_variables.scss';

.bg-dark-2 {
  background-color: #283353
}
.main-cta {
  margin: 60px auto;
}
.pricing {
  .price {
    font-size: 70px;
  }
  .discount {
    font-size: 30px;
    text-decoration: line-through;
    color: $muted;
  }
}
.deals-desc {
  max-width: 400px;
  margin: 50px 0;
}
</style>
